<div class="container container-fluid {{classDef}}">
    <div class="terms-logo">
        <div class="col-sm-4"
             *ngIf="!isPopup">
            <img class="logo"
                 src="{{otLogo}}"
                 alt="openText" />
        </div>
        <div class="terms-renewal"
             *ngIf="!isPopup">
            <b>{{'renewalNotice' | translate}} </b><br />
            <span *ngIf="renewalContractNumber"> {{'referenceNumber' | translate}} : {{renewalContractNumber}} </span>
        </div>
    </div>
    <div class="{{termContent}}">
        <div class="row">
            <div class="col-12">
                <h2 class="terms_header mb-6 pt-4">{{'termsConditons' | translate}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="terms-info">
                    <ul>
                        <p *ngIf="!isThirdPartyPublicSector">{{'terms_intro' | translate}}</p>
                        <li>{{'terms_first_part_1' | translate}} <a href="{{otAgreementLink}}"
                               target='_'>{{otAgreementLinkText}}</a> {{'terms_first_part_2' | translate}}</li>
                        <li>{{'terms_second' | translate}}</li>
                        <li>{{'terms_third' | translate}}</li>
                        <li> {{'terms_fourth' | translate}}</li>
                        <li><b>{{'terms_fifth_part_1' | translate}}:</b> {{'terms_fifth_part_2' | translate}}</li>
                        <li><b>{{'terms_hardware_part_1' | translate}}:</b> <span
                                  style="white-space: pre-line;">{{'terms_hardware_part_2' | translate}}</span></li>
                        <li><b>{{'terms_sixth_part_1' | translate}}:</b> {{'terms_sixth_part_2' | translate}}
                            <u>{{this.pageInfo}}</u>, {{'terms_sixth_part_3' | translate}}
                            <a href="javascript:void(0)"
                               *ngIf="!isPopup"
                               (click)="redirectMethod()">{{'termsHere' | translate}}</a>

                            <a href="javascript:void(0)"
                               *ngIf="isPopup"
                               (click)="detailsPage()">{{'termsHere' | translate}}</a>
                        </li>
                        <li *ngIf="!isThirdPartyPublicSector"><b>{{'terms_seventh_part_1' | translate}}:</b> {{'terms_seventh_part_2' | translate}}</li>
                        <div *ngIf="isThirdPartyPublicSector">
                            <li><b>{{'terms_eight_part_1' | translate}}:</b> {{'terms_eight_part_2' | translate}}</li>
                            <li>{{'terms_eight_part_3' | translate}}</li>
                            <li>{{'terms_eight_part_4' | translate}}</li>
                            <li>{{'terms_eight_part_5' | translate}}</li>
                            <li>{{'terms_eight_part_6' | translate}}</li>
                            <li><b>{{'terms_nine_part_1' | translate}}:</b> 
                                <ul>
                                    {{'terms_nine_part_2' | translate}} <a href="{{otAgreementLink}}"
                                    target='_'>{{otAgreementLinkText}}.</a> <br/> <br/>
                                    {{'terms_nine_part_3' | translate}}<br/><br/>
                                    {{'terms_nine_part_4' | translate}}<br/><br/>
                                    {{'terms_nine_part_5' | translate}}<br/><br/>
                                    {{'terms_nine_part_6' | translate}}<br/><br/>
                                    {{'terms_nine_part_7' | translate}}<br/><br/>
                                    {{'terms_nine_part_8' | translate}}<br/><br/>
                                    {{'terms_nine_part_9' | translate}}<br/><br/>
                                </ul>
                                
                            
                            </li>
                        </div>
                       
                    </ul>
                </div>
                <div *ngIf="isPopup"
                     class="mb-9">
                    <ng-container *ngFor="let btn of buttonGroup;">
                        <app-button-group (emitClickEvent)="onButtonClick($event)"
                                          [buttonConfig]="btn">
                        </app-button-group>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>