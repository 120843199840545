import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
import { pendingConstantsLbl } from '../../../pending/constants/pending-constant';
import { TersmConditionsConstants } from '../../constants/terms-condition.constants';
@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class TermsComponent extends TersmConditionsConstants implements OnInit {
    data: any
    classDef!: string
    termContent!: string
    isPopup = false
    buttonConfig: ButtonGroupInterface = {
        color: "blue",
        type: "justicon",
        icon: "close",
        classStyles: '',
        usecase: '',
        id: ''
    };
    @Input() renewalRef: string | undefined;
    @Input() renewalInfo?: any;
    @Output() tabChange: EventEmitter<any> = new EventEmitter();
    @Input() chkThirdPartyPublicSector: any;
    pageInfo!: string;
    otAgreementLinkText = pendingConstantsLbl.otAgreementLinkText;
    otLogo = pendingConstantsLbl.otLogo;
    otAgreementLink = pendingConstantsLbl.otAgreementLink;
    renewalContractNumber: string | undefined;
    buttonGroup: ButtonGroupInterface[] = this.termsComponentButtonGroup;
    isThirdPartyPublicSector?: boolean | undefined;

    
    constructor(
        private router: Router,
        translate: TranslateService,
        private route: ActivatedRoute,
        public dialogRef?: MatDialogRef<any>,
    ) {
        super(translate)
    }
    ngOnInit(): void {
        this.isPopup = this.data?.flag!;
        
        if (this.isPopup) {
            this.classDef = "terms-cond"
            this.termContent = "data-term-content"
            this.isThirdPartyPublicSector= this.data?.isThirdPartyPublicSector
        } else {
            this.classDef = ""
            this.termContent = "terms_content"
            this.isThirdPartyPublicSector= this.chkThirdPartyPublicSector
           
        };
       
        let refId = '';
        this.route.params.subscribe((params) => {
            refId = params['id'];
        });
        this.renewalInfo != undefined ? this.renewalContractNumber = this.renewalInfo?.renewalContractNumber : this.renewalContractNumber = refId;
       
        if (this.renewalInfo?.pageInfo == pendingConstantsLbl.active) {
            this.pageInfo = this.translate.instant('activeTC')
        }
        else if (this.renewalInfo?.pageInfo == pendingConstantsLbl.history) {
            this.pageInfo = this.translate.instant('historyTC')
        }
        else {
            this.pageInfo = this.translate.instant('pendingTC')
        }
    }
    redirectMethod() {
        const index = 0;
        this.tabChange.emit(index)
    }
    closePopUp() {
        this.dialogRef?.close();
    }

    detailsPage() {
        localStorage.setItem('rm', this.data.rm!);
        localStorage.setItem('rc', this.data.rcNumber!);
        localStorage.setItem("renewalUniqueID", this.data.rcID!);
        this.router.navigate([RoutingUrlConstants.ORC_PENDING_DETAILS + '/' + this.data.rcNumber!]);
        this.closePopUp();
    }
    onButtonClick(eventType: string) {
        this.dialogRef?.close(eventType);
    }

}
